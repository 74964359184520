<template>
  <div>
    <main class="space-y-10 router-view-container">
      <section>
        <h1>基本設定</h1>
        <div class="grid_wrap">
          <vs-card v-for="item in basic_menu" :key="item.title" @click="open_settings_menu(item.routerName)">
            <template #title>
              <div class="title">
                <svg-icon :icon-class="item.iconClass" className=""></svg-icon>
                <h2>{{ item.title }}</h2>
              </div>
            </template>
            <template #text>
              <p>{{ item.content }}</p>
            </template>
          </vs-card>
        </div>
      </section>

      <section>
        <h1>商店設定</h1>
        <div class="grid_wrap">
          <vs-card v-for="item in store_menu" :key="item.title" @click="open_settings_menu(item.routerName)">
            <template #title>
              <div class="title">
                <svg-icon :icon-class="item.iconClass" className=""></svg-icon>
                <h2>{{ item.title }}</h2>
              </div>
            </template>
            <template #text>
              <p>{{ item.content }}</p>
            </template>
          </vs-card>
        </div>
      </section>

      <section>
        <h1>應用介接</h1>
        <div class="grid_wrap">
          <vs-card v-for="item in extension_menu" :key="item.title" @click="open_settings_menu(item.routerName)">
            <template #title>
              <div class="title">
                <svg-icon :icon-class="item.iconClass" className=""></svg-icon>
                <h2>{{ item.title }}</h2>
              </div>
            </template>
            <template #text>
              <p>{{ item.content }}</p>
            </template>
          </vs-card>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  data() {
    return {
      basic_menu: [
        {
          iconClass: 'setting_general',
          title: '商店資料',
          content: '管理商店名稱和公司資料，以及管理您的品牌標誌。',
          routerName: 'basic_general',
        },
        // {
        //   iconClass: 'setting_account',
        //   title: '使用者權限',
        //   content: '管理網站基本資料',
        //   routerName: 'basic_account',
        // },
        {
          iconClass: 'setting_domains',
          title: '網域設定',
          content: '您可以在此更換新的子域名或使用您自有的網域。',
          routerName: 'setting_domains',
        },
        {
          iconClass: 'setting_webtools',
          title: '網站管理工具',
          content: '新增網站至 Google Search Console 和 Bing Webmaster Tools，分析並優化網站排名。',
          routerName: 'setting_web_manager',
        },
      ],
      store_menu: [
        {
          iconClass: 'setting_payments',
          title: '金流設定',
          content: '設定您的收款方式以及串接第三方金流來收取款項。',
          routerName: 'store_payments',
        },
        {
          iconClass: 'setting_shipping',
          title: '物流設定',
          content: '設定您的運送方式以及串接第三方物流來更有效率的寄出訂單。',
          routerName: 'store_shipping',
        },
        // {
        //   iconClass: 'setting_checkout',
        //   title: '結帳設定',
        //   content: '管理顧客的結帳權限和功能。',
        //   routerName: 'store_checkout',
        // },
        // {
        //   iconClass: 'setting_notifications',
        //   title: '通知設定',
        //   content: '管理網站基本資料',
        //   routerName: 'store_notifications',
        // },
        // {
        //   iconClass: 'setting_languages',
        //   title: '語系設定',
        //   content: '管理網站基本資料',
        //   routerName: 'store_languages',
        // },
      ],
      extension_menu: [
        {
          iconClass: 'setting_billing',
          title: '電子發票',
          content: '串接您的第三方電子發票商。',
          routerName: 'extension_billing',
        },
        {
          iconClass: 'setting_google',
          title: 'Google',
          content: '串接由 Google 提供的各項功能，如 Google分析 和 快速登入 等等。',
          routerName: 'extension_google',
        },
        {
          iconClass: 'setting_fb',
          title: 'Facebook',
          content: '串接由 Facebook 提供的各項功能，如 Messenger、像素和快速登入。',
          routerName: 'extension_facebook',
        },
        {
          iconClass: 'setting_line',
          title: 'LINE',
          content: '串接由 LINE 提供的各項功能。',
          routerName: 'extension_line',
        },
        {
          iconClass: 'setting_message',
          title: '簡訊',
          content: '串接第三方簡訊發送商，來發送優惠、促銷等簡訊給會員。',
          routerName: 'extension_message',
        },
      ],
    }
  },
  methods: {
    open_settings_menu(routerName) {
      this.$router
        .push({
          name: routerName,
        })
        .catch((err) => {})
    },
  },
}
</script>

<style lang="scss" scoped>
h1 {
  @apply text-gray-text_dark text-lg font-medium mb-5;
}

h2 {
  @apply text-gray-text_dark text-base font-medium;
}

p {
  @apply text-gray-text_light text-sm h-11;
}

.grid_wrap {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 sm:gap-5;
}

.title {
  @apply flex items-center space-x-2 text-gray-text_dark;
}
</style>
